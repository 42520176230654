<template>
  <div>
    <a-modal
      key="addEdit"
      title="新增/编辑"
      :width="670"
      :footer="null"
      :centered="true"
      :keyboard="false"
      :maskClosable="false"
      :bodyStyle="{ height: 'auto' }"
      :visible="modalAddEditVisible"
      @cancel="modalAddEditCancelChild"
    >

      <div v-show="modalAddEditCustomerPriceReuse != null">
        <a-form :form="modalReuseForm">
          <a-form-item label="企业客户规则复用">
            <a-select name="customerPriceReuse" placeholder="请选择企业客户规则" v-decorator="['customerPriceReuse', { initialValue: undefined }]" @change="handleCustomerPriceChange">
              <a-select-option v-for="(record, idx) in modalAddEditCustomerPriceReuse" :key="record.id" :value="idx">
                  {{ record.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <a-divider />
      </div>

      <a-form :form="modalAddEditForm" @submit="modalAddEditSubmit">
        <a-form-item class="price-type-wrapper">
          <a-radio-group
            name="priceType"
            v-decorator="['priceType', modalAddEditFormRules.priceType]"
            @change="handlePriceTypeChange"
          >
            <a-radio value="11">总价（散户利润）打折</a-radio>
            <a-radio value="12">总价（成本）加收</a-radio>
            <a-radio value="41">自定义价格结算公式</a-radio>
            <a-radio value="21">使用一口价</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          label="名称"
          :labelCol="{ lg: { span: 6 }, sm: { span: 8 } }"
          :wrapperCol="{ lg: { span: 12 }, sm: { span: 10 } }"
        >
          <a-input name="name" v-decorator="['name', modalAddEditFormRules.name]" />
        </a-form-item>

        <div v-show="fieldPriceType == '11'">
          <a-form-item
            label="总价折扣"
            :labelCol="{ lg: { span: 6 }, sm: { span: 8 } }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 10 } }"
          >
            <a-input-number
              name="discount"
              :min="0.01"
              :max="0.99"
              :step="0.01"
              v-decorator="['discount', modalAddEditFormRules.discount]"
            />
          </a-form-item>
        </div>

        <div v-show="fieldPriceType == '12'">
          <a-form-item
            label="每公斤加收"
            :labelCol="{ lg: { span: 6 }, sm: { span: 8 } }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 10 } }"
          >
            <a-input-number
              name="basicsAddedAlias12"
              :min="0"
              :step="0.01"
              v-decorator="['basicsAddedAlias12', modalAddEditFormRules.basicsAddedAlias12]"
            />
          </a-form-item>
        </div>

        <div v-show="fieldPriceType == '41'">
          <a-form-item
            label="排除成本"
            :labelCol="{ lg: { span: 6 }, sm: { span: 8 } }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 10 } }"
          >
            <a-checkbox-group
              name="costPriceExclude"
              v-decorator="['costPriceExclude', modalAddEditFormRules.costPriceExclude]"
            >
              <a-row>
                <a-col :span="8" v-for="(value, key) in fieldCostPriceExclude" :key="key">
                  <a-checkbox :value="key">{{ value }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-item>
        </div>

        <div v-show="fieldPriceType == '21'">
          <a-form-item
            label="一口价"
            :labelCol="{ lg: { span: 6 }, sm: { span: 8 } }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 10 } }"
          >
            <a-select
              name="relationPriceId"
              placeholder="请选择一口价"
              v-decorator="['relationPriceId', modalAddEditFormRules.relationPriceId]"
            >
              <a-select-option v-for="record in fixedPriceList" :key="record.id" :value="record.id">
                {{ record.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="自送到库每公斤减免"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-input-number
              name="pickupType2Derate"
              :min="0"
              :step="0.01"
              v-decorator="['pickupType2Derate', modalAddEditFormRules.pickupType2Derate]"
            />
            <a-button disabled class="percentage"> 元</a-button>
          </a-form-item>

          <a-form-item
            label="到库自取每公斤减免"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-input-number
              name="dispatchType2Derate"
              :min="0"
              :step="0.01"
              v-decorator="['dispatchType2Derate', modalAddEditFormRules.dispatchType2Derate]"
            />
            <a-button disabled class="percentage"> 元</a-button>
          </a-form-item>

          <a-form-item
            label="每公斤优惠"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-input-number
              name="basicsDerate"
              :min="0"
              :step="0.01"
              v-decorator="['basicsDerate', modalAddEditFormRules.basicsDerate]"
            />
            <a-button disabled class="percentage"> 元</a-button>
          </a-form-item>

          <a-form-item
            label="每公斤加收"
            :labelCol="{ lg: { span: 5 }, sm: { span: 6 } }"
            :wrapperCol="{ lg: { span: 16 }, sm: { span: 12 } }"
          >
            <a-input-number
              name="basicsAdded"
              :min="0"
              :step="0.01"
              v-decorator="['basicsAdded', modalAddEditFormRules.basicsAdded]"
            />
            <a-button disabled class="percentage"> 元</a-button>
          </a-form-item>
        </div>

        <a-form-item class="form-submit">
          <a-input name="id" type="hidden" style="display: none" v-decorator="['id', modalAddEditFormRules.id]" />
          <a-button style="margin-right: 15px" @click="modalAddEditCancelChild">取消</a-button>
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  customerPriceInsert,
  customerPriceUpdate,
  fixedPriceList,
} from '@/api/quotation'

const costPriceExclude = {
  1: '保价费',
  2: '操作费',
  3: '一级运费',
  4: '二级运费',
  5: '派件费',
  6: '到付手续费',
  7: '提货费',
  8: '其它服务费',
  9: '管理费',
}
const modalAddEditFormRules = {
  id: { initialValue: null },
  priceType: { initialValue: '21' },
  name: { rules: [{ required: true, message: '请输入名称' }] },
  discount: { initialValue: 0.01, rules: [{ required: true, message: '请输入总价折扣' }] },
  costPriceExclude: { initialValue: [], rules: [{ required: true, message: '请选择排除的成本' }] },
  relationPriceId: { rules: [{ required: true, message: '请选择一口价' }] },
  pickupType2Derate: { initialValue: '0' },
  dispatchType2Derate: { initialValue: '0' },
  basicsDerate: { initialValue: '0' },
  basicsAdded: { initialValue: '0' },
  basicsAddedAlias12: { initialValue: '0', rules: [{ required: true, message: '请输入每公斤加收' }] },
}
const modalAddEditFormField = {
  priceType11: ['id', 'priceType', 'name', 'discount'],
  priceType12: ['id', 'priceType', 'name', 'basicsAddedAlias12'],
  priceType21: ['id', 'priceType', 'name', 'relationPriceId', 'pickupType2Derate', 'dispatchType2Derate', 'basicsDerate', 'basicsAdded'],
  priceType41: ['id', 'priceType', 'name', 'costPriceExclude'],
}

export default {
  name: 'ModalForm',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    record: {
      type: Object,
      required: false,
      default: null,
    },
    confirm: {
      type: Function,
      required: true,
    },
    cancel: {
      type: Function,
      required: false,
      default: function(){},
    },
    customerType: {
      type: Number,
      required: false,
      default: 2,
    },
    customerPriceReuse: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    dataChange() {
      const {visible, record, customerPriceReuse} = this
      return {visible, record, customerPriceReuse}
    }
  },
  watch: {
    dataChange: {
      handler(newValue, oldValue){
        console.log(['watch-handler', newValue])
        this.modalAddEditVisible = newValue.visible
        this.modalAddEditRecord = newValue.record
        this.modalAddEditCustomerPriceReuse = newValue.customerPriceReuse

        this.modalReuseForm.resetFields()
        this.modalAddEditLoad(this.modalAddEditRecord, false);
      },
      deep: true,
    }
  },

  data() {
    return {
      modalAddEditVisible: this.visible,
      modalAddEditForm: this.$form.createForm(this),
      modalAddEditFormRules: modalAddEditFormRules,
      modalAddEditRecord: this.record,
      modalAddEditConfirm: this.confirm,
      modalAddEditCancel: this.cancel,
      modalAddEditCustomerType: this.customerType,
      modalAddEditCustomerPriceReuse: this.customerPriceReuse,
      modalReuseForm: this.$form.createForm(this),
      fieldPriceType: '21',
      fieldCostPriceExclude: costPriceExclude,
      fixedPriceList: [],
    }
  },
  mounted() {
    this.pageLoad()
  },
  methods: {
    pageLoad() {
      let that = this
      fixedPriceList({})
        .then((res) => {
          if (0 == res.code) {
            that.fixedPriceList = res.result.result
          }
        })
        .catch((e) => {
          console.info(e)
        })
    },
    modalAddEditLoad(record, reuseState) {
      this.modalAddEditForm.resetFields()
      if (record) {
        this.$nextTick(() => {
          var costPriceExclude = []
          if (record.costPriceExclude.indexOf(',') != -1) {
            costPriceExclude = record.costPriceExclude.split(',')
          } else if (record.costPriceExclude != '') {
            costPriceExclude = [record.costPriceExclude]
          }

          var recordId = null;
          if (!reuseState) {
            recordId = record.id
          } else if (this.modalAddEditRecord) {
            recordId = this.modalAddEditRecord.id
          }

          var setFieldsValue = {
            id: recordId,
            priceType: String(record.priceType),
            name: record.name,
            discount: record.discount,
            costPriceExclude: costPriceExclude,
            basicsDerate: record.costPriceDerateMap.basics,
            pickupType2Derate: record.costPriceDerateMap.pickupType2,
            dispatchType2Derate: record.costPriceDerateMap.dispatchType2,
            // basicsAdded: record.costPriceAddedMap.basics,
          }

          /**
           * 处理成本价加收字段costPriceAddedMap多类型服用逻辑
           * 价格类型：11总价折扣，12总价加收，21一口价，31自定义路线，41自定义成本
           * 总价加收对应输入框：basicsAddedAlias12
           * 一口价对应输入框：basicsAdded
           */
          if (setFieldsValue.priceType == '12') {
            setFieldsValue.basicsAddedAlias12 = record.costPriceAddedMap.basics
          } else if (setFieldsValue.priceType == '21') {
            setFieldsValue.basicsAdded = record.costPriceAddedMap.basics
          }

          if (record.relationPriceId) {
            setFieldsValue.relationPriceId = record.relationPriceId
          }

          this.modalAddEditForm.setFieldsValue(setFieldsValue)
          this.fieldPriceType = String(record.priceType)
        })
      } else {
        this.fieldPriceType = '21'
      }
    },
    modalAddEditCancelChild() {
      if (typeof(this.modalAddEditCancel) == 'function') {
        this.modalAddEditCancel();
      }
      this.modalAddEditVisible = false
    },
    modalAddEditSubmit(e) {
      e.preventDefault()
      let that = this
      const priceTypeField = 'priceType' + this.fieldPriceType
      this.modalAddEditForm.validateFields(modalAddEditFormField[priceTypeField], (err, values) => {
        if (!err) {
          console.log(values)
          if (this.fieldPriceType == '12') {
            values.costPriceAddedMap = {
              basics: (values.basicsAddedAlias12) ? parseFloat(values.basicsAddedAlias12) : 0,
            }
            delete values.basicsAddedAlias12
          }
          if (this.fieldPriceType == '21') {
            values.costPriceDerateMap = {
              basics: (values.basicsDerate) ? parseFloat(values.basicsDerate) : 0,
              pickupType2: (values.pickupType2Derate) ? parseFloat(values.pickupType2Derate) : 0,
              dispatchType2: (values.dispatchType2Derate) ? parseFloat(values.dispatchType2Derate) : 0,
            }
            values.costPriceAddedMap = {
              basics: (values.basicsAdded) ? parseFloat(values.basicsAdded) : 0,
            }
            delete values.basicsDerate
            delete values.pickupType2Derate
            delete values.dispatchType2Derate
            delete values.basicsAdded
          }
          if (this.fieldPriceType == '41') {
            values.costPriceExclude = values.costPriceExclude.join(',')
          }
          if (values.id) {
            that.handleUpdate(values)
          } else {
            that.handleInsert(values)
          }
        }
      })
    },
    handleInsert(parameter) {
      parameter.customerType = this.modalAddEditCustomerType
      customerPriceInsert(parameter)
        .then((res) => {
          let submitState = Boolean(0 == res.code)
          if (submitState) {
            this.modalAddEditVisible = false
          }
          if (typeof(this.modalAddEditConfirm) == 'function') {
            this.modalAddEditConfirm(submitState, res.result)
          }
        })
        .catch((e) => {
          console.info(e)
          if (typeof(this.modalAddEditConfirm) == 'function') {
            this.modalAddEditConfirm(false, null)
          }
        })
    },
    handleUpdate(parameter) {
      customerPriceUpdate(parameter)
        .then((res) => {
          let submitState = Boolean(0 == res.code)
          if (submitState) {
            this.modalAddEditVisible = false
          }
          if (typeof(this.modalAddEditConfirm) == 'function') {
            this.modalAddEditConfirm(submitState, res.result)
          }
        })
        .catch((e) => {
          console.info(e)
          if (typeof(this.modalAddEditConfirm) == 'function') {
            this.modalAddEditConfirm(false, null)
          }
        })
    },
    handlePriceTypeChange(e) {
      this.fieldPriceType = e.target.value
    },
    handleCustomerPriceChange(idx){
      this.modalAddEditLoad(this.modalAddEditCustomerPriceReuse[idx], true)
    }
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      border-radius:0 5px 5px 0;
      border-left: 0;
    }
  }
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 75%;
  float: right;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
/deep/ .price-type-wrapper {
  .ant-form-item-control-wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
</style>